<template>
  <div id="container" class="flex items-center justify-center w-full h-full">
    <div v-if="!isPreview" style="letter-spacing: -2px;" id="sudoImage" :class="isNav? 'text-base' : 'text-6xl'" class="flex items-center justify-center font-medium spacing">
      {{ text.toUpperCase() }}
    </div>
    <vs-avatar v-else :src="previewImage" class="w-full h-full" />
  </div>
</template>

<script>
export default {
  name: "SudoImage",
  props: {
    text: "",
    isPreview: false,
    previewImage: "",
    isNav: false
  }
}
</script>
