<template>
  <div class="the-navbar__user-meta flex items-center" v-if="activeUserInfo">
    <div class="text-right leading-tight hidden sm:block">
      <p class="font-semibold">{{ $store.state.AppActiveUser.fullName }}</p>
    </div>

    <vs-dropdown v-show="false" class="cursor-pointer">
      <div class="con-img ml-3">
        <img v-if="profileImage" key="onlineImg" :src="profileImage" alt="user-img" width="40" height="40" class="rounded-full shadow-md cursor-pointer block" />
        <div v-else style="width: 40px; height: 40px;">
          <SudoImage :text="initials" :isNav="true" :isPreview="false" previewImage="" />
        </div>
      </div>

      <vs-dropdown-menu class="vx-navbar-dropdown">
        <ul style="min-width: 9rem">
          <li class="flex py-2 cursor-pointer hover:bg-primary hover:text-white" @click="logout">
            <feather-icon icon="LogOutIcon" svgClasses="w-4 h-4" />
            <span class="text-sm">Logout</span>
          </li>
        </ul>
      </vs-dropdown-menu>
    </vs-dropdown>

    <div v-if="$route.name!== 'merchantIdVerification'">
      <small style="font-size:12px !important;">{{ $store.state.AppActiveUser.email }}</small>
      <div class="flex md:pt-2 cursor-pointer justify-end" v-if="$store.state.AppActiveUser.email">
        <a @click="logout" class="text-link">Log out</a>
      </div>
    </div>
  </div>
</template>

<script>
import SudoImage from "../../../../views/onboarding/components/SudoImage"
import { EventBus } from "../../../../event-bus";
export default {
  data() {
    return {
      test: "welcome",
      onboardingRequestId:this.$route.params.introducerRequestedId,
    };
  },
  components: {
    SudoImage
  },
  computed: {
    activeUserInfo() {
      return true;
      return localStorage.getItem("onboardingUser")
        ? JSON.parse(localStorage.getItem("onboardingUser"))
        : "";
    },
    profileImage() {
      const activeUser = this.$store.state.AppActiveUser;
      return  activeUser.photoURL &&
        `${
          activeUser.photoURL.includes('https://') || activeUser.photoURL.includes('http://') ?
            activeUser.photoURL :
            `${process.env.VUE_APP_API_URL}uploads/profileImage/${activeUser.photoURL}`
          }
        `
    },
    initials() {
      return this.activeUserInfo.firstName
        ? `${this.activeUserInfo.firstName.charAt(0)} ${this.activeUserInfo.lastName.charAt(0)}`
        : "";
    }
  },
  methods: {
    logout() {
      // If JWT login
      if (localStorage.getItem("accessToken")) {
        EventBus.$emit("logoutSuccess",{logout:true});
          this.$router.push({
            name: "onboarding-authenticate",
            params: { introducerRequestedId: this.onboardingRequestId  },
            query: { logout:true }
          });

      }
    }
  }
};
</script>