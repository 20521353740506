<template>
  <div class="relative">
    <div class="vx-navbar-wrapper navbar-full p-0">
      <vs-navbar class="navbar-custom navbar-skelton" :class="navbarClasses" :style="navbarStyle" :color="navbarColor">
        <div class="flex flex-wrap items-center justify-between w-full">
          <div class="flex">
            <template v-if="introducerLogo || onboardingLogo">
              <div v-if="onboardingLogo" class="mr-8">
                <img :src="onboardingLogo" alt="Rello" style="max-height:68px;max-width:170px;display:block;" />
              </div>
               <div v-else-if="introducerLogo" class="mr-8">
                <S3ImageGenerator :document="introducerLogo" :custom-class="'onboarding-image'" :is-base64="false" />
              </div>
              <div class="ds-wrapper flex flex-col cursor-pointer flex-wrap site-logo" v-if="Object.keys(merchant).length === 0">
                <p class="text-xxs text-body">POWERED BY</p>
                <img v-if="appName === 'FlipPay'" src="@/assets/images/logo/flippay-logo.svg" alt="Flippay" />
                <img v-else-if="appName=== 'RelloPay'" src="@/assets/images/logo/rellopay-logo.svg" alt="Rellopay" />
              </div>
            </template>
            <template v-else>
              <div class="cursor-pointer flex flex-wrap items-center mr-8 site-logo" v-if="Object.keys(merchant).length === 0">
                <img v-if="appName === 'FlipPay'" src="@/assets/images/logo/flippay-logo.svg" alt="Flippay" />
                <img v-else-if="appName=== 'RelloPay'" src="@/assets/images/logo/rellopay-logo.svg" alt="Rellopay" />
              </div>
            </template>
          </div>

          <div class="text-center flex items-center partner-navbar onboarding-navbar">
            <ul>
              <li><a :href="helpSrc" class="" target="_blank">Help</a></li>
              <li><a :href="`mailto:${contactUs}`" target="_blank" class="">Contact us</a></li>
            </ul>
          </div>
          <div class="text-center flex items-center">
            <profile-drop-down />
          </div>
        </div>
      </vs-navbar>
    </div>
  </div>
</template>

<script>
import ProfileDropDown from "./components/ProfileDropDown.vue";
import { mapGetters } from "vuex";
import S3ImageGenerator from "../../../views/onboarding/components/S3ImageGenerator";

export default {
  name: "the-navbar-horizontal",
  data() {
    return {
      serverUrl: process.env.VUE_APP_API_URL,
      appName: process.env.VUE_APP_NAME ||"FlipPay",
      helpSrc: process.env.VUE_APP_NAME === "FlipPay" ? "https://support.flippay.com.au/knowledge" : "https://help.rellopay.co",
      contactUs: process.env.VUE_APP_NAME === "FlipPay" ? "onboard@flippay.com.au" : "onboard@rellopay.co",
    };
  },
  props: {
    logo: { type: String },
    navbarType: {
      type: String,
      required: true
    }
  },
  components: {
    ProfileDropDown,
    S3ImageGenerator
  },
  methods: {
    formattedUrl(url) {
      if (!url) return "";
      return url.includes("http") ? url : `https://${url}`;
    }
  },
  mounted() {},
  computed: {
    ...mapGetters("onboardMerchant", ["GET_INTRODUCER_LOGO", "GET_ONBOARDING_LOGO"]),

    introducerLogo() {
      return this.GET_INTRODUCER_LOGO;
    },

    onboardingLogo() {
      return this.GET_ONBOARDING_LOGO;
    },

    merchant() {
      return localStorage.getItem("merchant") ? JSON.parse(localStorage.getItem("merchant")) : "";
    },
    navbarColor() {
      let color = "#fff";
      if (this.navbarType === "sticky") {
        color = "#f7f7f7";
      } else if (this.navbarType === "static") {
        if (this.scrollY < 50) {
          color = "#f7f7f7";
        }
      }

      this.isThemedark === "dark"
        ? color === "#fff"
          ? (color = "#10163a")
          : (color = "#262c49")
        : null;

      return color;
    },
    isThemedark() {
      return this.$store.state.theme;
    },
    navbarStyle() {
      return this.navbarType === "static"
        ? { transition: "all .25s ease-in-out" }
        : {};
    },
    navbarClasses() {
      return this.scrollY > 5 && this.navbarType === "static"
        ? null
        : "d-theme-dark-light-bg ";
    },
    scrollY() {
      return this.$store.state.scrollY;
    },
    verticalNavMenuWidth() {
      return this.$store.state.verticalNavMenuWidth;
    },
    windowWidth() {
      return this.$store.state.windowWidth;
    }
  }
};
</script>

<style>
.vs-navbar.navbar-skelton {
  padding: .8rem 2.2rem;
}

.vs-navbar {
  height: auto !important;
}

@media (max-width: 576px) {
  .vs-navbar {
    padding: 0.625rem 1rem!important;
  }
}
</style>